import axios from "axios"
import { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Divider,
  DatePicker,
  TimePicker,
  Select,
  Space,
  Card,
  Typography,
  message,
  List,
  Skeleton,
  Table,
  Tooltip,
  Badge,
  Statistic,
  Modal,
  Avatar,
  Switch,
  Tag,
  Popconfirm,
} from "antd"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  NodeIndexOutlined,
  PlusOutlined,
  DiffOutlined,
  PercentageOutlined,
  HomeOutlined,
  LoadingOutlined,
  InfoCircleTwoTone,
  UserOutlined,
  MailTwoTone,
  QuestionCircleOutlined
} from "@ant-design/icons"
import { AutoComplete } from "antd"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"
import Meta from "antd/es/card/Meta"
import { useAuth } from "../../contexts/authContext"

const { Option } = Select
const { Text } = Typography
const AddLead = () => {
  const { RangePicker } = DatePicker;
  const { currentUser, roles } = useAuth()
  const isAFS = roles.includes("AFS")
  const [data, setData] = useState()
  const [schoolId, setSchoolId] = useState({
    verified:false,
    previousValue:''
  })
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [total, settotal] = useState(0)
  const [analytics, setAnalytics] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [school, setSchool] = useState()
  const { width } = useView()
  const navigate = useNavigate()
  const searchInput = useRef(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const [value, setValue] = useState()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { isMobile } = useView()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [modalText, setModalText] = useState("Content of the modal")
  const showModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds")
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    console.log("Clicked cancel button")
    setOpen(false)
  }

  const getPanelValue = async (searchText) => {
    if (!searchText) return []
    setOptions([{ value: "loading ...." }])
    console.log("seach text ", searchText)
    try {
      const where = JSON.stringify({
        ascii_name: {
          $regex: "^" + searchText,
          $options: "i",
        },
      })
      const url = `https://parseapi.back4app.com/classes/Indiacities_india_cities_database?limit=10&keys=ascii_name,latitude,longitude&where=${where}`
      const header = {
        // "X-Parse-Application-Id": process.env.REACT_APP_CITY_API_ID,
        // "X-Parse-REST-API-Key": process.env.REACT_APP_CITY_API_KEY,
        "X-Parse-Application-Id": "QGbWT4XShAT2mbLgskFhhR8IsKlUwIzbg60sPofs",
        "X-Parse-REST-API-Key": "W3nwydEEB9VswTJY46aVZDdnd6QTZW1tiiaCcDce",
        "Authorization": `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
      const res = await axios.get(url, {
        headers: header,
      })

      if (res.status !== 200) return []

      return res?.data?.results.map((city) => {
        return { value: city.ascii_name, key: city.objectId }
      })
    } catch (error) {
      setLoading(false)
      console.log(error.response)
      return []
    }
  }
  const fetchSchool = async () => {
    setTableLoading(true)
    let query = []
    console.log(tableParams)
    Object.keys(tableParams.filters).forEach((k) => {
      if (
        tableParams.filters[k] !== null &&
        tableParams.filters[k].length > 0
      ) {
        // query.push(`${k}=${tableParams.filters[k]}`)
        if (k === "name") {
          query.push(`${k}:sw=${tableParams.filters[k]}`)
        }else if (k === "city") {
          query.push(`${k}:sw=${tableParams.filters[k]}`)
        }else if(k==='nextFollowUp'){
          query.push(`nextFollowUp:gte=${new Date(tableParams.filters[k][0]).toISOString()}&nextFollowUp:lt=${new Date(tableParams.filters[k][1]).toISOString()}`);
        }else if(k==='orientationDate'){
          query.push(`orientationDate:gte=${new Date(tableParams.filters[k][0]).toISOString()}&orientationDate:lt=${new Date(tableParams.filters[k][1]).toISOString()}`);
        }else if(k==='lastReportDate'){
          query.push(`lastReportDate:gte=${new Date(tableParams.filters[k][0]).toISOString()}&lastReportDate:lt=${new Date(tableParams.filters[k][1]).toISOString()}`);
        }else if(k==='sessionStartDate'){
          query.push(`sessionStartDate:gte=${new Date(tableParams.filters[k][0]).toISOString()}&sessionStartDate:lt=${new Date(tableParams.filters[k][1]).toISOString()}`);
        } else {
          query.push(`${k}=${tableParams.filters[k]}`)
        }
      }
    })
    //   name={$regex:^fin,$options:'i'}&

    query =
      `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&` +
      query.join("&")
    if(Object.keys(tableParams.filters).length === 0){
      let queryLocal = sessionStorage.getItem('leadcrmb2b')
      if(queryLocal)
      query = queryLocal
    }else{
      sessionStorage.setItem('leadcrmb2b', query)
    }
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/school/fetchSchools?${isAFS?"partner=AFS&":""}${query}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setSchool(res.data.data.schools)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchSchool()
  }, [JSON.stringify(tableParams)])

  const handleSave = async (values) => {
    console.log(values)
    const orientationDate = new Date(values.date)
    const orientationTime = new Date(values.time)
    orientationDate.setHours(
      orientationTime.getHours(),
      orientationTime.getMinutes(),
      0,
      0
    )
    setLoading(true)
    try {
      const body = {
        schoolId: values.schoolId,
        name: values.name,
        numberOfStudents: values.numberOfStudents,
        notes: values.notes,
        city: values.city,
        refferalCode: values.refferalCode,
        state: values.state,
        orientationDate: orientationDate,
        ...(values.customPrice && { customPrice: values.customPrice }),
        POC: {
          name: values.POC_Name,
          contact: values.POC_Phone,
          email: values.POC_Email,
          designation: values.POC_Designation,
        },
        nextFollowUp: new Date(values.nextFollowUp),
        sessionStartDate : new Date(values.sessionStartDate),
        schoolCoordinator: values.POC_Name,
        inTake: values.inTake,
        mainGroupLink: values.mainGroupLink,
        logoUrl: values.logoUrl,
        leadType: values.leadType,
        POC_V1:values.POC_V1,
        partner:values.partner
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/school/createAndUpdateSchools`
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      messageApi.open({
        type: "success",
        content: "Saved successfully",
      })
      // update the record here, so that no explicit refresh is required
      setSchool((schools) => {
        return schools.map((school) => {
          return school.schoolId === body.schoolId ? body : school
        })
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
      setOpen(false)
      fetchSchool()
    }
  }
  const handleReset = (clearFilters) => {
    // sessionStorage.setItem('leadcrmb2b','page=1&limit=10&')
    sessionStorage.clear()
    clearFilters()
    fetchSchool()
  }
  const fetchSchoolId = async (id) => {
    setIsModalOpen(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/school/getAnalyticsById/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res)
      setAnalytics(res.data.data)
      setModalLoading(false)
    } catch (error) {
      setModalLoading(false)
      console.log(error)
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const onSelect = (data) => {
    console.log("onSelect", data)
  }
  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    isAFS ?{
      width:0
    }:{
      title: "Type",
      width: 80,
      dataIndex:'partner',
      render: (_, record) => {
        return <>
        {record.partner === "AFS" && <Tag color="blue">AFS</Tag>}
        {record.partner === "YOUVAH" && <Tag color="purple">YouVah</Tag>}
        </>
      },
      ...getColumnSearchProps("partner"),
    },
    {
      title: "School Name",
      dataIndex: "name",
      //   fixed: "left",
      width: 195,
      sorter: false,
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              form.resetFields()
              form.setFieldsValue(record)
              if (record.orientationDate) {
                const date = dayjs(record.orientationDate)
                form.setFieldValue("date", date)
                form.setFieldValue("time", date)
              }
              if (record.nextFollowUp) {
                const date = dayjs(record.nextFollowUp)
                form.setFieldValue("nextFollowUp", date)
                // form.setFieldValue("time", date)
              }
              if (record.sessionStartDate) {
                const date = dayjs(record.sessionStartDate)
                form.setFieldValue("sessionStartDate", date)
                // form.setFieldValue("time", date)
              }
              if (record.POC) {
                form.setFieldValue("POC_Name", record.POC.name)
                form.setFieldValue("POC_Phone", record.POC.contact)
                form.setFieldValue("POC_Email", record.POC.email)
                form.setFieldValue("POC_Designation", record.POC.designation)
              }
              setSchoolId({
                ...schoolId,previousValue:record.schoolId
              })
              showModal()
            }}
          >
            {record.name}
          </a>
        )
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "City",
      dataIndex: "city",
      width: 120,
      sorter: false,
      //   fixed: "left",
      ...getColumnSearchProps("city"),
    },
    {
      title: "School POC Details",
      dataIndex: "POC",
      sorter: false,
      width: 150,
      render: (data) =>
        data ? (
          <Tooltip
            color="white"
            title={
              <Card
                bordered={false}
                size="small"
                style={{
                  minWidth: 150,
                }}
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "#971eff",
                      }}
                      icon={<UserOutlined />}
                    />
                  }
                  title={data.name}
                  description={`Designation : ${data.designation || "UNKOWN"}`}
                />
                <p>✉️ : {data.email || "N/A"}</p>
                <p>📞 : {data.contact || "N/A"}</p>
              </Card>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                size={32}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "gray",
                  marginRight: "10px",
                }}
              >
                {data.name ? data.name[0] : <UserOutlined />}
              </Avatar>
              <p>{data.name}</p>
            </div>
          </Tooltip>
        ) : (
          "N/A"
        ),
    },
    {
      title: "YouVah POC",
      dataIndex: "POC_V1",
      sorter: false,
      width: 150,

      render: (data) =>
        data ? (
          <p style={{ color: "gray" }}>{data}</p>
        ) : (
          <p>N/A</p>
        ),
        ...getColumnSearchProps("POC_V1"),
    },
    {
      title: "Website Details",
      dataIndex: "refferalCode",
      sorter: false,
      width: 150,
      render: (data, record) =>
        data ? (
          <Tooltip
            color="white"
            title={
              <Card
                bordered={false}
                size="small"
                style={{
                  minWidth: 150,
                }}
              >
                <p>
                  🔗 :{" "}
                  {(
                    <Text
                      copyable={{
                        text: `https://intern.youvah.com/school/${data}`,
                      }}
                    >{`https://intern.youvah.com/school/${data}`}</Text>
                  ) || "N/A"}
                </p>
                <p>code : {data || "N/A"}</p>
              </Card>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>
                {" "}
                <Tag color={"green"}>Active</Tag>
              </p>
            </div>
          </Tooltip>
        ) : (
          <Tag color={"red"}>N/A</Tag>
        ),
    },
    {
      title: "Lead Type",
      dataIndex: "leadType",
      width: 120,
      filters: [
        {
          text: "HOT",
          value: "HOT",
        },
        {
          text: "COLD",
          value: "COLD",
        },
        {
          text: "CONVERTED",
          value: "CONVERTED",
        },
        {
          text: "DIRECT",
          value: "DIRECT",
        },
      ],
      render: (leadType) => {
        let color = {
          HOT: "geekblue",
          COLD: "volcano",
          CONVERTED: "green",
        }
        return <Tag color={color[leadType]}>{leadType}</Tag>
      },
    },
    isAFS ?{
      width:0
    }:{
      title: "Next Follow up",
      dataIndex: "nextFollowUp",
      sorter: false,
      width: 150,

      render: (date) =>
        date ? (
          <p style={{ color: "gray" }}>{new Date(date).toDateString()}</p>
        ) : (
          <p>N/A</p>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={selectedKeys[0] ? [selectedKeys[0], selectedKeys[1]] : []}
              onChange={([from, to]) => setSelectedKeys([from, to])}
              onOk={() => confirm()}
            />
            <Space>
              <button
                type="button"
                onClick={() => {
                  setSelectedKeys([]);
                  clearFilters();
                }}
                style={{ width: 90 }}
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                style={{ width: 90 }}
              >
                OK
              </button>
            </Space>
          </div>
        ),
        // Customize the filter icon
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    isAFS ?{
      width:0
    }:{
      title: "Notes",
      dataIndex: "notes",
      width: 255,
      render: (notes) => {
        return <div style={{ color: "black" }}>{notes}</div>
      },
    },
    {
      title: "Orientation Date",
      dataIndex: "orientationDate",
      sorter: false,
      width: 150,
      render: (orientationDate, record) =>
        orientationDate ? (
          <>
            {new Date(orientationDate).toDateString()}
            <div style={{ color: "lightgray" }}>
              {new Date(orientationDate).toLocaleTimeString()}
            </div>
          </>
        ) : (
          "N/A"
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={selectedKeys[0] ? [selectedKeys[0], selectedKeys[1]] : []}
              onChange={([from, to]) => setSelectedKeys([from, to])}
              onOk={() => confirm()}
            />
            <Space>
              <button
                type="button"
                onClick={() => {
                  setSelectedKeys([]);
                  clearFilters();
                }}
                style={{ width: 90 }}
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                style={{ width: 90 }}
              >
                OK
              </button>
            </Space>
          </div>
        ),
        // Customize the filter icon
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    isAFS ?{
      width:0
    }:{
      title: "Session Start Date",
      dataIndex: "sessionStartDate",
      sorter: false,
      width: 150,
      render: (orientationDate, record) =>
        orientationDate ? (
          <>
            {new Date(orientationDate).toDateString()}
            <div style={{ color: "lightgray" }}>
              {new Date(orientationDate).toLocaleTimeString()}
            </div>
          </>
        ) : (
          "N/A"
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={selectedKeys[0] ? [selectedKeys[0], selectedKeys[1]] : []}
              onChange={([from, to]) => setSelectedKeys([from, to])}
              onOk={() => confirm()}
            />
            <Space>
              <button
                type="button"
                onClick={() => {
                  setSelectedKeys([]);
                  clearFilters();
                }}
                style={{ width: 90 }}
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                style={{ width: 90 }}
              >
                OK
              </button>
            </Space>
          </div>
        ),
        // Customize the filter icon
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    isAFS ?{
      width:0
    }:{
      title: "Last Report Date",
      dataIndex: "lastReportDate",
      sorter: false,
      width: 150,
      render: (orientationDate, record) =>
        orientationDate ? (
          <>
            {new Date(orientationDate).toDateString()}
            <div style={{ color: "lightgray" }}>
              {new Date(orientationDate).toLocaleTimeString()}
            </div>
          </>
        ) : (
          "N/A"
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={selectedKeys[0] ? [selectedKeys[0], selectedKeys[1]] : []}
              onChange={([from, to]) => setSelectedKeys([from, to])}
              onOk={() => confirm()}
            />
            <Space>
              <button
                type="button"
                onClick={() => {
                  setSelectedKeys([]);
                  clearFilters();
                }}
                style={{ width: 90 }}
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                style={{ width: 90 }}
              >
                OK
              </button>
            </Space>
          </div>
        ),
        // Customize the filter icon
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
            {!isAFS &&<Tooltip title="Edit">
              <Button
                type="link"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  form.resetFields()
                  form.setFieldsValue(record)
                  if (record.orientationDate) {
                    const date = dayjs(record.orientationDate)
                    form.setFieldValue("date", date)
                    form.setFieldValue("time", date)
                  }
                  if (record.nextFollowUp) {
                    const date = dayjs(record.nextFollowUp)
                    form.setFieldValue("nextFollowUp", date)
                    // form.setFieldValue("time", date)
                  }
                  if (record.sessionStartDate) {
                    const date = dayjs(record.sessionStartDate)
                    form.setFieldValue("sessionStartDate", date)
                    // form.setFieldValue("time", date)
                  }
                  if (record.POC) {
                    form.setFieldValue("POC_Name", record.POC.name)
                    form.setFieldValue("POC_Phone", record.POC.contact)
                    form.setFieldValue("POC_Email", record.POC.email)
                    form.setFieldValue(
                      "POC_Designation",
                      record.POC.designation
                    )
                  }
                  setSchoolId({
                    ...schoolId,previousValue:record.schoolId
                  })
                  showModal()
                }}
              />
            </Tooltip>}
            <Tooltip title="Get Analytics">
              <Button
                type="text"
                shape="circle"
                onClick={() => {
                  setData(record)
                  setModalLoading(true)
                  fetchSchoolId(record._id)
                }}
                icon={<PercentageOutlined style={{ color: "#45C153" }} />}
              />
            </Tooltip>
            {!isAFS && <Popconfirm
    title="Are you Sure you want to send Email to School ?"
    description="This action will be irreversible..."
    placement="left"
    onConfirm={()=>{
      sendb2bOnboardingReport(record._id)
    }}
    icon={
      <QuestionCircleOutlined
        style={{
          color: 'red',
        }}
      />
    }
  >
            <Tooltip placement="left" title="Send Report After Onboarding">
              <Button
                type="text"
                shape="circle"
                icon={<MailTwoTone  style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            </Popconfirm>}
            <Tooltip placement="left" title={`${record.name} Lead CRM`}>
              <Button
                type="text"
                shape="circle"
                onClick={() => navigate(`/b2b/school/leadcrm/${record._id}`)}
                icon={<NodeIndexOutlined style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            <Tooltip placement="left"  title={`${record.name} Student Report`}>
              <Button
                type="text"
                shape="circle"
                onClick={() => navigate(`/b2b/school/report/${record._id}`)}
                icon={<DiffOutlined style={{ color: "red" }} />}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        {!isAFS && <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            form.resetFields()
            setSchoolId({
              ...schoolId,previousValue:''
            })
            showModal()
          }}
          loading={loading}
          style={{ backgroundColor: "#971eff", color: "white" }}
        >
          Add New Lead
        </Button>}
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => {
            sessionStorage.clear();
            setTableParams({ ...tableParams, filters: {} })
          }}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchSchool()}
          loading={loading}
        >
          Reload
        </Button>
      </Space>
    )
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSchool([])
    }
  }
  const renderStatus = (_, record) => {
    if (record.leadType === "DIRECT") {
      return <RocketTwoTone twoToneColor="green" style={{ fontSize: "25px" }} />
    }
    if (record.leadType === "CONVERTED")
      return (
        <CheckOutlined
          twoToneColor="lightgreen"
          style={{ color: "lightGreen", fontSize: "25px" }}
        />
      )
    if (record.profile) {
      return <BankOutlined />
    }

    if (record.userInterested === "NO") {
      return (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: "20px" }} />
      )
    }

    if (record.new) {
      if (record.oldUser) {
        return (
          <>
            <Badge color="blue" />
            <Tooltip title="Repeating user :)">
              <StarFilled style={{ color: "green" }} />
            </Tooltip>
          </>
        )
      } else {
        return <Badge color="blue" />
      }
    }

    if (
      record.nextFollowUp &&
      new Date(record.nextFollowUp).toLocaleDateString() ===
        new Date().toLocaleDateString()
    )
      return (
        <ClockCircleTwoTone
          twoToneColor="orange"
          style={{ fontSize: "25px" }}
        />
      )
  }

  const sendb2bOnboardingReport =async(value)=>{
   try{
    const url = `${constants.NEW_DOMAIN}/api/v1/report/b2b/generateReportAfterOnBoarding`
    const res = await axios.post(url, { 
      schoolId:value 
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    messageApi.open({
      type: "success",
      content: "Email Sent successfully",
    })
   }catch(error){
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
   }
  }

  const checkUserName =async(value)=>{
    if(!/^[a-zA-Z0-9]+$/.test(value))
      return
     if(value === schoolId.previousValue){
      return setSchoolId({...schoolId,verified:false})
     }
   try{
    const url = `${constants.NEW_DOMAIN}/api/v1/school/checkSchoolId`
    const res = await axios.post(url, { 
      schoolId:value 
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    setSchoolId({
      ...schoolId,verified:false
    })
   }catch(error){
    setSchoolId({
      ...schoolId,verified:'This School Id is Already Taken'
    })
   }
  }

  const check = (value) => {
   const timerId = setTimeout(() => {
     if(value !== "")
     checkUserName(value)
    }, 1000); // Adjust the delay time (in milliseconds) as needed
    return () => clearTimeout(timerId);
  };

  if (loading) {
    return <Loader />
  }


  return (
    <div style={{ backgroundColor: "#fff", padding: "20px" }}>
      {contextHolder}
      <Modal
        title="Add/Update Tab"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        footer={""}
        style={{ height: "400px" }}
        scroll={true}
        onCancel={handleCancel}
      >
        <Card title="School Details">
          <Form
            layout="horizontal"
            form={form}
            size="medium"
            onFinish={handleSave}
            style={{
              maxWidth: 600,
              marginTop: "40px",
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
              <Form.Item
              label="School Id"
              name="schoolId"
              validateStatus={schoolId.verified ? 'error' : ''}
              help={schoolId.verified || ''}
              rules={[
                {
                  required: true,
                  message: "School Id is required",
                },
              ]}
              
            >
            <Input placeholder="Enter School Id" onChange={(e)=>{check(e.target.value)}} />
            </Form.Item>
            <Form.Item
              label="School Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "School name is required",
                },
              ]}
            >
              <Input placeholder="Enter School" />
            </Form.Item>
            
            <Form.Item
              label="🌐City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
            >
              <AutoComplete
                options={options}
                style={{
                  width: 200,
                }}
                onSelect={onSelect}
                onSearch={async (text) => {
                  const res = await getPanelValue(text)
                  setOptions(res)
                }}
                placeholder="Enter City"
              />
            </Form.Item>

            <Form.Item label="~ student strength" name="numberOfStudents">
              <Input type="tel" placeholder="eg : 100" />
            </Form.Item>
            <Form.Item label="🔗 WhatsApp Link" name="mainGroupLink">
              <Input placeholder="Link" />
            </Form.Item>
            <Form.Item label="Refferal Code" name="refferalCode">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="🔗 Logo URL"
              name="logoUrl"
              rules={[
                {
                  type: "url",
                  warningOnly: true,
                  message: "Please Enter valid URL",
                },
              ]}
            >
              <Input placeholder="Logo URL" />
            </Form.Item>
            <Divider plain orientation="left" style={{ color: "gray" }}>
              Add POC details
            </Divider>
            <Form.Item label="Name" name="POC_Name">
              <Input placeholder="enter name" />
            </Form.Item>
            <Form.Item label="Designation" name="POC_Designation">
              <Input placeholder="eg : Teacher" />
            </Form.Item>
            <Form.Item
              label="📞 Phone"
              name="POC_Phone"
              rules={[
                { min: 10, message: "Please check phone number" },
                { max: 10, message: "Please check phone number" },
              ]}
            >
              <Input placeholder="phone number" type="tel" />
            </Form.Item>
            <Form.Item
              label="✉️ Email"
              name="POC_Email"
              rules={[{ type: "email", message: "Please check email" }]}
            >
              <Input placeholder="email@gmail.com" type="email" />
            </Form.Item>

            <Divider plain orientation="left" style={{ color: "gray" }}>
              Orientation details
            </Divider>

            <Form.Item label="⏰Date & Time">
              <Space>
                <Form.Item name="date">
                  <DatePicker
                    placement="topLeft"
                    format="YYYY-MM-DD"
                    // style={{
                    //   width: "100%",
                    // }}
                  />
                </Form.Item>
                <Form.Item name="time">
                  <TimePicker showNow placement="topLeft" />
                </Form.Item>
              </Space>
            </Form.Item>

            <Divider plain orientation="left" style={{ color: "gray" }}>
              Others
            </Divider>
            <Form.Item name="customPrice" label="💸Custom Price">
              <Input placeholder="999" />
            </Form.Item>
            <Form.Item label="⌚Next follow-up">
              <Form.Item name="nextFollowUp">
                <DatePicker
                  placement="topLeft"
                  format="YYYY-MM-DD"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Session Start Date">
              <Form.Item name="sessionStartDate">
                <DatePicker
                  placement="topLeft"
                  format="YYYY-MM-DD"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="📝notes" name="notes">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="inTake"
              label="Accept Response"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Tooltip title="Show school in club onboarding form">
              <InfoCircleTwoTone
                style={{
                  fontSize: "16px",
                  color: "#08c",
                  marginLeft: `${isMobile ? "60px" : "200px"}`,
                  position: "absolute",
                  marginTop: "-46",
                }}
              />
            </Tooltip>
            <Form.Item name="leadType" label="Lead Type">
              <Select style={{ maxWidth: "200px" }}>
                <Option value="HOT">HOT</Option>
                <Option value="COLD">COLD</Option>
                <Option value="CONVERTED">CONVERTED</Option>
              </Select>
            </Form.Item>
            <Form.Item name="POC_V1" label="YouVah POC">
              <Select style={{ maxWidth: "200px" }}>
                <Option value="ROHIT">ROHIT</Option>
                <Option value="DEVESH">DEVESH</Option>
              </Select>
            </Form.Item>
            <Form.Item name="partner" label="Partner">
              <Select style={{ maxWidth: "200px" }}>
                <Option value="YOUVAH">YouVah</Option>
                <Option value="AFS">AFS</Option>
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={
                width > 800
                  ? {
                      offset: 8,
                    }
                  : {}
              }
            >
              <Button
                type="primary"
                style={{ width: "200px" }}
                htmlType="submit"
              >
                📂 Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      {/* analytics */}
      {data && (
        <Modal
          // title={data.name + ", " + (data.city || "")}
          title={
            modalLoading ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Avatar
                  size={data.logoUrl ? 64 : 32}
                  src={data.logoUrl}
                  icon={!data.logoUrl && <HomeOutlined />}
                  style={!data.logoUrl && { opacity: "0.3" }}
                />
                <p>
                  {modalLoading ? "" : data.name + ", " + (data.city || "")}
                </p>
              </div>
            )
          }
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          okButtonProps={{
            display: false,
          }}
          footer={[]}
          style={{ maxWidth: isMobile ? "350px" : "500px", fontSize: "20px" }}
        >
          {modalLoading ? (
            <LoadingOutlined
              width={"50px"}
              style={{
                marginLeft: "43%",
                fontSize: "30px",
                marginTop: "10%",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                School Id :
                <p style={{ fontWeight: "bold", marginLeft: "4px" }}>
                  {data.schoolId}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                Group Link :{" "}
                <a
                  style={{ fontWeight: "bold", marginLeft: "4px" }}
                  href={"https://bit.ly/" + data.mainGroupLink}
                  target="_blank"
                >
                  {data.mainGroupLink
                    ? "https://bit.ly/" + data.mainGroupLink
                    : "Not Available"}
                </a>
              </div>
              <Divider>Registrations</Divider>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "135px 125px"
                    : "135px 125px 125px",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                }}
              >
                <Card bordered={false}>
                  <Statistic
                    title={"Total revenue (INR)"}
                    value={analytics?.revenue}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Leads"}
                    value={analytics?.results}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Paid Users"}
                    value={analytics?.paid}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Training Completed"}
                    value={analytics?.training}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Assessment Completed"}
                    value={analytics?.assessment}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Internship Started"}
                    value={analytics?.internship}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Success Stories"}
                    value={analytics?.successStories}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
              </div>
            </>
          )}
        </Modal>
      )}

      <Row>
        <Col xs={24} md={20}>
          <div className="text-3xl">Schools</div>
          <p className="text-lg-light">
            {new Date().toDateString()} - {new Date().toLocaleTimeString()}
          </p>
        </Col>

        <Col xs={24} md={4}>
          <Statistic
            title="Total School Leads"
            loading={loading}
            value={tableParams.pagination.total}
            precision={0}
            valueStyle={{
              color: "blue",
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="Schools"
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        loading={tableLoading}
        title={renderHeader}
        size="medium"
        rowClassName="row"
        dataSource={school}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        rowKey={(record) => record._id}
        scroll={{
          x: 900,
          y: 500,
        }}
      />
    </div>
  )
}
export default AddLead
