import axios from "axios"
import { useEffect, useState, useRef } from "react"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  FilterOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  HistoryOutlined,
  NodeIndexOutlined,
  SmileOutlined,
  StarTwoTone,
  PropertySafetyTwoTone,
  CloudDownloadOutlined
} from "@ant-design/icons"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tooltip,
  Modal,
  message,
  Empty,
  Timeline,
  Checkbox,
  List,
  Typography,
  notification,
  Tag,
  Progress,
  Flex
} from "antd"

import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { Form } from "antd"
import { useAuth } from "../../contexts/authContext"
import useView from "../../hooks/useView"
import FamLogo from "../../images/fam-logo.jpg"
import { socket } from "../../util/socket"
import { logDOM } from "@testing-library/react"
import notify from "../../Sounds/sound.mp3"
import { Howl } from "howler"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { DateRange, DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import excelJS from "exceljs"
import * as FileSaver from "file-saver"
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
}

const ThirdPartyLeadCRM = () => {
  const [data, setData] = useState([])
  const [percent, setPercent] = useState(0)
  const [modalType,setModalType]=useState('FILTER')
  const [loading, setLoading] = useState(false)
  const { Text } = Typography
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filterArray: [],
  })
  const [edit, setEdit] = useState({
    id: "test",
    name: "test",
    orderId: "test",
  })
  const { currentUser, roles } = useAuth()
  const isPOCViewable =
    roles.includes("SALES_ADMIN") ||
    roles.includes("SUPPORT") ||
    roles.includes("OPERATIONS")
  const isEditable = roles.includes("ADMIN") || roles.includes("SALES_B2C")
  const isAdmin = roles.includes("ADMIN")
  const [messageApi, contextHolder] = message.useMessage()
  const [NotifyApi, contextNofify] = notification.useNotification()
  const [system, setSystem] = useState([])
  const searchInput = useRef(null)
  const { isMobile } = useView()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRefferalOpen, setIsModalRefferalOpen] = useState(false)
  const [refferal, setRefferal] = useState(undefined)
  const [showHistory, setShowHistory] = useState(undefined)
  const [rangePickerState, setRangePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  const [filterModelOpen, setFilterModelOpen] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const showRefferalModal = async (record) => {
    // console.log(record);
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/refferal`
      const res = await axios.put(url, {
        email: record.email,
      })
      // console.log(res.data.data.referalcode.code)
      setRefferal(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
    setIsModalRefferalOpen(true)
  }
  const handleRefferalOk = () => {
    setIsModalRefferalOpen(false)
  }
  const handlerefferalCancel = () => {
    setIsModalRefferalOpen(false)
  }
  const onReset = () => {
    form.resetFields()
  }
  const getPOC = () => {
    const arr = []
    for (const key in constants.idToFilter) {
      arr.push({
        text: constants.idToFilter[key],
        value: key,
      })
    }
    return arr
  }

  const onFinish = async (values) => {
    const queryObject = []
    Object.keys(values).map((key) => {
      if (key === "date" && values[key]) {
        // console.log(values["date"]);
        let startDate = values[key].startDate
        let endDate = values[key].endDate
        let sd = new Date(startDate.getTime()).toISOString()
        let ed = new Date(endDate.getTime())
        ed = addDays(ed.setHours(0, 0, 0), 1).toISOString()

        return queryObject.push(
          // `createdAt_v1:gte=${values[key].startDate}&createdAt_v1:lte=${values[key].endDate}`
          `createdAt_v1:gte=${sd}&createdAt_v1:lt=${ed}`
        )
      }

      if (typeof values[key] === "string") {
        return queryObject.push(`${key}=${values[key]}`)
      }
      if (typeof values[key] === "object" && values[key]?.operator) {
        return queryObject.push(
          `${key}:${values[key].operator}=${values[key].value}`
        )
      }
    })
    console.log("parsed query Array", queryObject)
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
      filterArray: queryObject,
    })
    setFilterModelOpen(false)
  }
  const onDownload = async (values) => {
    const queryObject = []
    Object.keys(values).map((key) => {
      if (key === "date" && values[key]) {
        let startDate = values[key].startDate
        let endDate = values[key].endDate
        let sd = new Date(startDate.getTime()).toISOString()
        let ed = new Date(endDate.getTime())
        ed = addDays(ed.setHours(0, 0, 0), 1).toISOString()

        return queryObject.push(
          // `createdAt_v1:gte=${values[key].startDate}&createdAt_v1:lte=${values[key].endDate}`
          `createdAt_v1:gte=${sd}&createdAt_v1:lt=${ed}`
        )
      }

      if (typeof values[key] === "string") {
        return queryObject.push(`${key}=${values[key]}`)
      }
      if (typeof values[key] === "object" && values[key]?.operator) {
        return queryObject.push(
          `${key}:${values[key].operator}=${values[key].value}`
        )
      }
    })
    console.log("parsed query Array", queryObject)
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
      filterArray: queryObject,
    })
    // setFilterModelOpen(false)
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      //   fixed: "left",
      width: 150,
      sorter: false,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: false,
      width: 150,
      //   fixed: "left",
      render: (phone, record) => (
        <>
          {`(${record.countryCode})`} {phone}
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryCode",
      sorter: false,
      width: 95,
      render: (data, record) => (
        <>
          {record.programType === "PROCOURSE" ? (
            <>
              <Tag color={"volcano"}>{data}</Tag>
            </>
          ) : record.programType === "MASTERUNION" ? (
            <>
              <Tag color={"#f9d133"}>{data}</Tag>
            </>
          ) : record.programType === "MENTORNSHIP" ? (
            <>
              <Tag color={"green-inverse"}>{data}</Tag>
            </>
          ) : (
            <>{data}</>
          )}
        </>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
          {data ? (
            <>
              <Tag color={data === "HYBRID" ? "purple-inverse" : "#b658ff"}>
                {data}
              </Tag>
            </>
          ) : record.programType === "MASTERUNION" ? (
            <>
              <Tag color={"cyan"}>Offline</Tag>
            </>
          ) : (
            <>
              <Tag color={"red"}>Not Selected</Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: "DOR(MM/DD/YY)",
      dataIndex: "createdAt_v1",
      width: 150,
      sorter: false,

      render: (date) => (
        <>
          {new Date(date).toLocaleDateString()}
          <br />
          <div style={{ color: "lightgray" }}>
            {new Date(date).toLocaleTimeString()}
          </div>
        </>
      ),
    },
    {
      title: "Lead Type",
      dataIndex: "leadType",
      width: 150,
      render: (leadType, record) => {
        return <>{leadType}</>
      },
    },
    {
      title: "Source",
      dataIndex: "utm_source",
      width: 150,
    },
    {
      title: "Medium",
      dataIndex: "utm_medium",
      width: 150,
    },
    {
      title: "Campaign",
      dataIndex: "utm_campaign",
      width: 150,
    },
    {
      title: "Content",
      dataIndex: "utm_content",
      width: 150,
    },
    {
      title: "Placement",
      dataIndex: "utm_placement",
      width: 150,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      width: 150,
      render: (comments, record) => {
        return (
          <TextArea
            rows={3}
            // value={comments}
            defaultValue={comments}
            onChange={(e) => {
              console.log(e.target.value)
              record.comments = e.target.value
            }}
          />
        )
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {setFilterModelOpen(true)
            setModalType("FILTER")
          }}
          loading={loading}
        >
          Add Filter
        </Button>
        <Button
          type="dashed"
          icon={<CloudDownloadOutlined />}
          onClick={() => {setFilterModelOpen(true)
            setModalType("DOWNLOAD")
          }}
          loading={loading}
        >
          Download 
        </Button>
        <Button
          type="default"
          // icon={<ReloadOutlined />}
          icon={<CloseCircleTwoTone />}
          onClick={() => {
            form.resetFields()
            setRangePickerState([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ])
            setTableParams({ ...tableParams, filterArray: [] })
          }}
          loading={loading}
        >
          Clear All Filters
        </Button>
      </Space>
    )
  }
  const fetchExcelData = async (page) => {
    try {
      let query =
        `page=${page}&limit=1000&firebaseId=${currentUser._delegate.uid}&` +
        tableParams.filterArray.join("&")
      if(modalType ==='DOWNLOAD'){
        query= query + '&download=true'
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/marketingData?${query}`
      const res = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      return res.data.data.users
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const exportExcel = async (data,count) => {
    let batchSize = Math.ceil(count / 1000)
    const workbookBatch = new excelJS.Workbook()
    setModalType('PROGRESS')
    console.log(batchSize);
    const workSMM = workbookBatch.addWorksheet("Report")
    workSMM.columns = [
      { header: "Name", key: "name", width: 10 },
      // { header: "Email", key: "email", width: 10 },
      { header: "comments", key: "comments", width: 10 },
      { header: "leadType", key: "leadType", width: 10 },
      { header: "utm_source", key: "utm_source", width: 10 },
      { header: "utm_medium", key: "utm_medium", width: 10 },
      { header: "utm_campaign", key: "utm_campaign", width: 10 },
      { header: "utm_content", key: "utm_content", width: 10 },
      { header: "utm_placement", key: "utm_placement", width: 10 },
    ]
    
    for (let i = 1; i <= batchSize; i++) {
      const data = await fetchExcelData(i)
      await data?.map((data, index) => {
        workSMM.addRow({
          name: data?.name,
          // email: data?.email,
          // phone: data?.phone,
          leadType : data?.leadType,
          comments: data?.comments,
          utm_source: data?.utm_source,
          utm_medium: data?.utm_medium,
          utm_campaign: data?.utm_campaign,
          utm_content: data?.utm_content,
          utm_placement: data?.utm_placement
        })
      })
      setPercent(Math.round((i/batchSize)*100))
    }
    
   
    workbookBatch.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      })
      FileSaver.saveAs(blob, `Marketing_Report.xlsx`)
    })
    setModalType('FILTER')
    setFilterModelOpen(false)
    form.resetFields()
    setRangePickerState([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ])
    setTableParams({ ...tableParams, filterArray: [] })
    setPercent(0)
  }
  const fetchData = async () => {
    if(modalType !=='DOWNLOAD')
    setLoading(true)
    console.log("tableParams : ", tableParams)
    try {
      let query =
        `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&` +
        tableParams.filterArray.join("&")

      if(modalType ==='DOWNLOAD'){
        query= query + '&download=true'
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/marketingData?${query}`
      const res = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      if(modalType ==='DOWNLOAD'){
        exportExcel(res.data.data.users,res.data.results)
      }
      else{
        setData(res.data.data.users)
        setLoading(false)
      }
      

      setTableParams((d) => {
        return {
          ...d,
          pagination: { ...d.pagination, total: res.data.results },
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    tableParams.filterArray,
  ])

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    setTableParams({
      ...tableParams,
      pagination,
    })
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }
  return (
    <>
      {contextHolder}
      {contextNofify}

      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        <Modal
          title={modalType==="DOWNLOAD" || modalType==="PROGRESS" ?"Download Marketing Data":"Add Filter"}
          open={filterModelOpen}
          onOk={() => {
            // trigger search
          }}
          onCancel={() => {
            setFilterModelOpen(false)
          }}
          footer={[]}
        >
         {modalType === 'PROGRESS'?<>
         {/* <Flex gap="small" vertical> */}
    <p style={{marginBottom:'10px'}}>Please Wait while we are generating your report</p>
    <Progress percent={percent} />
    <p><b style={{color:'red'}}>NOTE :</b> Please do not refresh or close the window </p>
    {/* </Flex> */}
         </>: <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={modalType?onDownload:onFinish}
            layout="horizontal"
            size="small"
          >
            <Divider orientation="left" plain style={{ color: "blue" }}>
              UTM filters
            </Divider>
            <Form.Item label="Source">
              <Space.Compact>
                <Form.Item name={["utm_source", "operator"]} noStyle>
                  <Select>
                    <Select.Option value="eq">equals</Select.Option>
                    <Select.Option value="cnts">contains</Select.Option>
                    <Select.Option value="nq">is not</Select.Option>
                    <Select.Option value="sw">starts with</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={["utm_source", "value"]} noStyle>
                  <Input placeholder="source" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Campaign">
              <Space.Compact>
                <Form.Item name={["utm_campaign", "operator"]} noStyle>
                  <Select>
                    <Select.Option value="eq">equals</Select.Option>
                    <Select.Option value="cnts">contains</Select.Option>
                    <Select.Option value="nq">is not</Select.Option>
                    <Select.Option value="sw">starts with</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={["utm_campaign", "value"]} noStyle>
                  <Input placeholder="campaign" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Medium">
              <Space.Compact>
                <Form.Item name={["utm_medium", "operator"]} noStyle>
                  <Select>
                    <Select.Option value="eq">equals</Select.Option>
                    <Select.Option value="cnts">contains</Select.Option>
                    <Select.Option value="nq">is not</Select.Option>
                    <Select.Option value="sw">starts with</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={["utm_medium", "value"]} noStyle>
                  <Input placeholder="medium" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Content">
              <Space.Compact>
                <Form.Item name={["utm_content", "operator"]} noStyle>
                  <Select>
                    <Select.Option value="eq">equals</Select.Option>
                    <Select.Option value="cnts">contains</Select.Option>
                    <Select.Option value="nq">is not</Select.Option>
                    <Select.Option value="sw">starts with</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={["utm_content", "value"]} noStyle>
                  <Input placeholder="content" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Placement">
              <Space.Compact>
                <Form.Item name={["utm_placement", "operator"]} noStyle>
                  <Select>
                    <Select.Option value="eq">equals</Select.Option>
                    <Select.Option value="cnts">contains</Select.Option>
                    <Select.Option value="nq">is not</Select.Option>
                    <Select.Option value="sw">starts with</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={["utm_placement", "value"]} noStyle>
                  <Input placeholder="placement" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Divider orientation="left" plain style={{ color: "blue" }}>
              Lead Level Filters
            </Divider>
            <Form.Item name="date" label="Registration Date"></Form.Item>
            <DateRangePicker
              ref={searchInput}
              onChange={(item) => {
                form.setFieldValue("date", item.selection)
                setRangePickerState([item.selection])
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={rangePickerState}
              direction="vertical"
            />
            <Form.Item name="categoryCode" label="Course Category">
              <Select>
                <Select.Option value={undefined}>NONE</Select.Option>
                <Select.Option value="GD">GD (Graphic Design)</Select.Option>
                <Select.Option value="CW">CW (Content Writing)</Select.Option>
                <Select.Option value="VE">VE (Video Editing)</Select.Option>
                <Select.Option value="SMM">SMM (Social Media)</Select.Option>
                <Select.Option value="WD">WD (Web Dev)</Select.Option>
                <Select.Option value="FM">FM (Finance Managment)</Select.Option>
                <Select.Option value="BA">BA (Business Analytics)</Select.Option>
                <Select.Option value="GDPRO">
                  GDPRO (Graphics Design PRO)
                </Select.Option>
                <Select.Option value="DM">DM (Digital Marketing)</Select.Option>
                <Select.Option value="PSYPRO">PSYPRO (Psychology Pro)</Select.Option>
                <Select.Option value="CWPRO">CWPRO (Copy Writing Pro)</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="plan" label="Plan">
              <Select>
                <Select.Option value={undefined}>NONE</Select.Option>
                <Select.Option value="HYBRID">
                  Premium Plan (hybrid)
                </Select.Option>
                <Select.Option value="RECORDED">
                  Basic Plan (recorded)
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="callRecipient" label="Call Recipient">
              <Select>
                <Select.Option value={undefined}>NONE</Select.Option>
                <Select.Option value="STUDENT">
                  Student
                </Select.Option>
                <Select.Option value="PARENT">
                  Parent
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="leadType" label="Lead Type">
              <Select placement='topLeft'>
                <Select.Option value={undefined}>NONE</Select.Option>
                <Select.Option value="HOT">HOT</Select.Option>
                <Select.Option value="COLD">COLD</Select.Option>
                <Select.Option value="NOT INTERESTED">
                  NOT INTERESTED
                </Select.Option>
                <Select.Option value="CONVERTED">CONVERTED</Select.Option>
                <Select.Option value="INVALID NUMBER">INVALID NUMBER</Select.Option>
                <Select.Option value="AGE NOT VALID">AGE NOT VALID</Select.Option>
                <Select.Option value="CALL NOT PICKED TEXT SENT">CALL NOT PICKED TEXT SENT</Select.Option>
                <Select.Option value="JUNK">JUNK</Select.Option>
                <Select.Option value="DIRECT">DIRECT</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 14 }}>
              <Space>
                <Button size="medium" type="primary" htmlType="submit">
                 {modalType === "DOWNLOAD"?"Download":"Apply Filter"}
                </Button>
                <Button
                  size="medium"
                  type="default"
                  onClick={() => {
                    form.resetFields()
                    setRangePickerState([
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                      },
                    ])
                  }}
                >
                  Clear All
                </Button>
              </Space>
            </Form.Item>
          </Form>}
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Leads CRM</div>
            <p className="text-lg-light">
              last updated : {new Date().toDateString()} -{" "}
              {new Date().toLocaleTimeString()}
            </p>
          </Col>
          <Col xs={24} md={4}>
            <Statistic
              title="Total Registrations"
              loading={loading}
              value={tableParams.pagination?.total}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="leads"
            />
          </Col>
        </Row>

        <Table
          theme="dark"
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          title={renderHeader}
          pagination={tableParams.pagination}
          loading={loading}
          rowClassName="row"
          onChange={handleTableChange}
          size="small"
          scroll={{
            x: 1500,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}
export default ThirdPartyLeadCRM
