import React,{useEffect,useState} from 'react'
import {
    ZoomInOutlined,
    FileSearchOutlined,
    HomeOutlined,
    FullscreenOutlined,
    BarsOutlined,
    AppstoreOutlined,BulbOutlined,ApartmentOutlined,DingdingOutlined,FireOutlined,AlertOutlined
  } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme,Form,Input,Button,Row,Col,Card,List,Typography,Space,Badge, Divider,Tag,Statistic,
    Timeline,Empty,Modal,DatePicker,Switch,Tooltip,message,Skeleton,Avatar,Popover,Segmented,Alert,Result} from 'antd';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import constants from '../../constants';
import useView from '../../hooks/useView';
import Loader from '../../util/loader';
const { Header, Content, Footer, Sider } = Layout;


function CurrentAnalytics() {
    const [batch, setBatch] = useState([]);
    const [view, setView] = useState("Live");
    const [type, setType] = useState({
        Live :[],
        Ongoing:[],
        Upcoming:[]
    });
    const [loading, setLoading] = useState(false)
    const {isMobile} = useView()
    const Navigate = useNavigate()
    const { Meta } = Card
    const [messageApi, contextHolder] = message.useMessage()
    const [analytics, setAnalytics] = useState()
    const [isModalAnalyticsOpen, setIsModalAnalyticsOpen] = useState(false);
    const fetchData = async()=>{
        setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/batch/activeBatchesAnalytics`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res.data.data.results);
      setBatch(res.data.data.results)
      setLoading(false)
      let Live = []
      let Ongoing = []
      let Upcoming = []
      res?.data?.data?.results.map((data)=>{
        if(data.liveDayDetails !== null)
        Live.push(data)
        if(data.liveDayDetails === null && data.day > 0)
        Ongoing.push(data)
        if(data.liveDayDetails === null && data.day < 0)
        Upcoming.push(data)
      })
      setType({
        Live,
        Ongoing,
        Upcoming
      })
      messageApi.open({
        type: "success",
        content:"Data Found Successfully",
      })
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error)
    }
    }
    console.log(type)
    const getAnalytics =async(batchId)=>{
        // setLoading(true)
        try {
          const url = `${constants.NEW_DOMAIN}/api/v1/batch/analytics/${batchId}`
          const res = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
            }
          })
          console.log(res.data.data.result);
          setAnalytics(res.data.data.result)
          setLoading(false)
          messageApi.open({
            type: "success",
            content:"Analytics Found Successfully",
          })
        } catch (error) {
          setLoading(false)
          messageApi.open({
            type: "error",
            content: error.response.data.message || "Something went wrong",
          })
          console.log(error)
        }
    }
    let ss="hjdjshdhjs#kljkljwlkdjflkdw#lwjelkjflwekjf#"

    useEffect(() => {
    fetchData()
    }, []);
    
    if (loading) {
        return <Loader />
      }
        
  return (
   <>
    {contextHolder}
    <Modal title={`Analytics`} open={isModalAnalyticsOpen}  footer={""} onCancel={()=>setIsModalAnalyticsOpen(false)}>
   <Divider>Registrations</Divider>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "155px 155px",
                    // : "135px 125px 125px",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                }}
              >
                <Card bordered={false}>
                  <Statistic
                    title={"Total User"}
                    value={analytics?.totalStudents}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Training Started"}
                    value={analytics?.trainingStarted}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
               </div> 
               <Divider>Assessment</Divider>
               <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "155px 155px",
                    // : "135px 125px 125px",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                }}
              > <Card bordered={false}>
              <Statistic
                title={"MCQ Given"}
                value={analytics?.assessment.mcq}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
                // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Portfolio Submitted"}
                value={analytics?.assessment.portfolio}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
                // prefix={<ArrowDownOutlined />}
              />
            </Card>

              </div>
              <Divider>Internship</Divider>
               <div
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "135px 125px"
                    : "135px 125px 125px",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                }}
              >
                 <Card bordered={false}>
                  <Statistic
                    title={"Slot Seleted"}
                    value={analytics?.internship?.slotBooked}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Company Assigned"}
                    value={analytics?.internship?.companyAssigned}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Week 1 Submitted"}
                    value={analytics?.internship?.week1}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Week 2 Submitted"}
                    value={analytics?.internship?.week2}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Week 3 Submitted"}
                    value={analytics?.internship?.week3}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title={"Week 4 Submitted"}
                    value={analytics?.internship?.week4}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
           
              </div>
    </Modal>
           <div style={{ backgroundColor: "#F5F5F7", padding: "20px" }}>
            <h1>{view} Batchess</h1>
            <div style={{background:"white", padding:"12px", width:"fit-content",marginBottom:"15px"}}>
            <Segmented
            size="large"
            onChange={(e)=>setView(e)}
             item
    options={[
      {
        label: 'Live Classess Batches',
        value: 'Live',
        icon: <BulbOutlined style={{color:"red"}}/>,
      },
      {
        label: 'Ongoing Batches',
        value: 'Ongoing',
        icon: <ApartmentOutlined style={{color:"green"}}/>,
      },
      {
        label: 'Upcoming Batches',
        value: 'Upcoming',
        icon: <DingdingOutlined style={{color:"#971eff"}} />,
      },
      {
        label: 'All',
        value: 'All',
        icon: <FireOutlined  style={{color:"orange"}} />,
      },
    ]}
  />
  </div>
    {
        view === "Live"?<>
        {
         type.Live.length === 0 ? <Result
         status="warning"
         title="Today there is no live class"
        //  extra={
        //    <Button type="primary" key="console">
        //      Go Console
        //    </Button>
        //  }
       />:<>
            <Row gutter={[16, 24]}>
              {type.Live?.map((s, i) => {
                return (
                  <Col xs={24} md={8} key={i}>
                    <Card
                    bordered
                    // style={{backgroundColor:s.error ===null ?"":"4px solid red",}}
                      actions={[
                        <div
                          onClick={() => {
                          Navigate(`/batch/modify?batch=${s.code}`)
                          }}
                        >
                          Edit Details <ZoomInOutlined />
                        </div>,
                        <div
                          onClick={async () => {
                            getAnalytics(s.code)
                            setIsModalAnalyticsOpen(true)
                          }}
                        >
                          Fetch Analytics <FileSearchOutlined />
                        </div>,
                      ]}
                    >
                      <Skeleton loading={loading} avatar>
                        <Meta
                        //   avatar={
                            // <Avatar
                            //   size={32}
                            //   style={{ backgroundColor: s.error ===null ?'#87d068':"red" }}
                            // //   src={s.logoUrl}
                            //   icon={<HomeOutlined />}
                            // //   style={ {opacity: "0.3" }}
                            // />
                        //   }
                        //   title={s.code}
                          description={
                            <>
                            <div style={{color:"black"}}>
                            <p style={{fontWeight:"bold",fontSize:"18px"}}>{s.code}</p>
                             {s.liveDayDetails && (<>
                               <p style={{width:"100%",display:"flex",justifyContent:"row",alignItems:"baseline"}}> <div className="live"></div> <span style={{marginLeft:"10px"}}> <Popover content={
                                <>
                                <p>🚀ID : {s.liveDayDetails.id}</p>
                                <p>🔗URL : {s.liveDayDetails.url}</p>
                                <p>📅Date : {new Date(s.liveDayDetails.date).toLocaleString()}</p>
                                </>
                               } title="Live class Details" trigger="click">
      <Button type="dashed"  size='small'><b>Live </b></Button>
    </Popover> </span> </p>
                             </>)}   
                             <p >Active : <b>Day {s.day}</b></p>
                             <p >Start Date : {new Date(s.startDate).toLocaleString()}</p>
                             <p >Is Slot Active :  {s?.acceptingRegistration ?<Tag color={"green"}>Yes</Tag>:<Tag color={"red"}>No</Tag>}</p>
                             <p >Group Link  : 
          <a
                  style={{ fontWeight: "", marginLeft: "4px" }}
                  href={"https://bit.ly/" + s?.groupLink}
                  target="_blank"
                >
                  {s?.groupLink
                    ? "https://bit.ly/" + s?.groupLink
                    : "Not Available"}
                </a></p>
                {/* {s.error && ( <p style={{color:"red"}}><b><AlertOutlined style={{color:"red"}}/>{s.error}</b></p>)} */}
                {s.error && ( <p style={{color:"red"}}><b>{s.error.split("#").map((data)=>{
                  return (<>
                  <p><AlertOutlined style={{color:"red"}}/> {data}</p>
                  </>)
                })}</b></p>)}
                             </div>
                            </>
                          }
                        />
                      </Skeleton>
                    </Card>
                  </Col>
                )
              })}
        </Row>
         </>
        }
        </>:view ==="Ongoing"?<>
        {
         type.Ongoing.length === 0 ?<Result
         status="warning"
         title="There are no Ongoing Batches"
        //  extra={
        //    <Button type="primary" key="console">
        //      Go Console
        //    </Button>
        //  }
       />:<>
            <Row gutter={[16, 24]}>
              {type.Ongoing?.map((s, i) => {
                return (
                  <Col xs={24} md={8} key={i}>
                    <Card
                    bordered
                    // style={{backgroundColor:s.error ===null ?"":"4px solid red",}}
                      actions={[
                        <div
                          onClick={() => {
                          Navigate(`/batch/modify?batch=${s.code}`)
                          }}
                        >
                          Edit Details <ZoomInOutlined />
                        </div>,
                        <div
                          onClick={async () => {
                            getAnalytics(s.code)
                            setIsModalAnalyticsOpen(true)
                          }}
                        >
                          Fetch Analytics <FileSearchOutlined />
                        </div>,
                      ]}
                    >
                      <Skeleton loading={loading} avatar>
                        <Meta
                        //   avatar={
                            // <Avatar
                            //   size={32}
                            //   style={{ backgroundColor: s.error ===null ?'#87d068':"red" }}
                            // //   src={s.logoUrl}
                            //   icon={<HomeOutlined />}
                            // //   style={ {opacity: "0.3" }}
                            // />
                        //   }
                        //   title={s.code}
                          description={
                            <>
                            <div style={{color:"black"}}>
                            <p style={{fontWeight:"bold",fontSize:"18px"}}>{s.code}</p>
                             {s.liveDayDetails && (<>
                               <p style={{width:"100%",display:"flex",justifyContent:"row",alignItems:"baseline"}}> <div className="live"></div> <span style={{marginLeft:"10px"}}> <Popover content={
                                <>
                                <p>🚀ID : {s.liveDayDetails.id}</p>
                                <p>🔗URL : {s.liveDayDetails.url}</p>
                                <p>📅Date : {new Date(s.liveDayDetails.date).toLocaleString()}</p>
                                </>
                               } title="Live class Details" trigger="click">
      <Button type="dashed"  size='small'><b>Live </b></Button>
    </Popover> </span> </p>
                             </>)}   
                             <p >Active : <b>Day {s.day}</b></p>
                             <p >Start Date : {new Date(s.startDate).toLocaleString()}</p>
                             <p >Is Slot Active :  {s?.acceptingRegistration ?<Tag color={"green"}>Yes</Tag>:<Tag color={"red"}>No</Tag>}</p>
                             <p >Group Link  : 
          <a
                  style={{ fontWeight: "", marginLeft: "4px" }}
                  href={"https://bit.ly/" + s?.groupLink}
                  target="_blank"
                >
                  {s?.groupLink
                    ? "https://bit.ly/" + s?.groupLink
                    : "Not Available"}
                </a></p>
                {/* {s.error && ( <p style={{color:"red"}}><b><AlertOutlined style={{color:"red"}}/>{s.error}</b></p>)} */}
                {s.error && ( <p style={{color:"red"}}><b>{s.error.split("#").map((data)=>{
                  return (<>
                  <p><AlertOutlined style={{color:"red"}}/> {data}</p>
                  </>)
                })}</b></p>)}
                
                             </div>
                            </>
                          }
                        />
                      </Skeleton>
                    </Card>
                  </Col>
                )
              })}
        </Row>
         </>
        }
        </>:view==="Upcoming"?<>
        {
         type.Upcoming.length === 0 ?<Result
         status="warning"
         title="There are no Upcoming Batches"
        //  extra={
        //    <Button type="primary" key="console">
        //      Go Console
        //    </Button>
        //  }
       />:<>
            <Row gutter={[16, 24]}>
              {type.Upcoming?.map((s, i) => {
                return (
                  <Col xs={24} md={8} key={i}>
                    <Card
                    bordered
                    // style={{backgroundColor:s.error ===null ?"":"4px solid red",}}
                      actions={[
                        <div
                          onClick={() => {
                          Navigate(`/batch/modify?batch=${s.code}`)
                          }}
                        >
                          Edit Details <ZoomInOutlined />
                        </div>,
                        <div
                          onClick={async () => {
                            getAnalytics(s.code)
                            setIsModalAnalyticsOpen(true)
                          }}
                        >
                          Fetch Analytics <FileSearchOutlined />
                        </div>,
                      ]}
                    >
                      <Skeleton loading={loading} avatar>
                        <Meta
                        //   avatar={
                            // <Avatar
                            //   size={32}
                            //   style={{ backgroundColor: s.error ===null ?'#87d068':"red" }}
                            // //   src={s.logoUrl}
                            //   icon={<HomeOutlined />}
                            // //   style={ {opacity: "0.3" }}
                            // />
                        //   }
                        //   title={s.code}
                        //   style={{fontWeight:"bold"}}
                          description={
                            <>
                            <div style={{color:"black"}}>
                             <p style={{fontWeight:"bold",fontSize:"18px"}}>{s.code}</p>
                             {s.liveDayDetails && (<>
                               <p style={{width:"100%",display:"flex",justifyContent:"row",alignItems:"baseline"}}> <div className="live"></div> <span style={{marginLeft:"10px"}}> <Popover content={
                                <>
                                <p>🚀ID : {s.liveDayDetails.id}</p>
                                <p>🔗URL : {s.liveDayDetails.url}</p>
                                <p>📅Date : {new Date(s.liveDayDetails.date).toLocaleString()}</p>
                                </>
                               } title="Live class Details" trigger="click">
      <Button type="dashed"  size='small'><b>Live </b></Button>
    </Popover> </span> </p>
                             </>)}   
                             <p >Batch Starts in : <b>T {s.day} days</b></p>
                             <p >Start Date : {new Date(s.startDate).toLocaleString()}</p>
                             <p >Is Slot Active :  {s?.acceptingRegistration ?<Tag color={"green"}>Yes</Tag>:<Tag color={"red"}>No</Tag>}</p>
                             <p >Group Link  : 
          <a
                  style={{ fontWeight: "bold", marginLeft: "4px" }}
                  href={"https://bit.ly/" + s?.groupLink}
                  target="_blank"
                >
                  {s?.groupLink
                    ? "https://bit.ly/" + s?.groupLink
                    : "Not Available"}
                </a></p>
                {/* {s.error && ( <p style={{color:"red"}}><b><AlertOutlined style={{color:"red"}}/>{s.error}</b></p>)} */}
                {s.error && ( <p style={{color:"red"}}><b>{s.error.split("#").map((data)=>{
                  return (<>
                  <p><AlertOutlined style={{color:"red"}}/> {data}</p>
                  </>)
                })}</b></p>)}
                             </div>
                            </>
                          }
                        />
                      </Skeleton>
                    </Card>
                  </Col>
                )
              })}
        </Row>
         </>
        }
        </>:<>
        <Row gutter={[16, 24]}>
              {batch?.map((s, i) => {
                return (
                  <Col xs={24} md={8} key={i}>
                    <Card
                    bordered
                    // style={{backgroundColor:s.error ===null ?"":"4px solid red",}}
                      actions={[
                        <div
                          onClick={() => {
                          Navigate(`/batch/modify?batch=${s.code}`)
                          }}
                        >
                          Edit Details <ZoomInOutlined />
                        </div>,
                        <div
                          onClick={async () => {
                            getAnalytics(s.code)
                            setIsModalAnalyticsOpen(true)
                          }}
                        >
                          Fetch Analytics <FileSearchOutlined />
                        </div>,
                      ]}
                    >
                      <Skeleton loading={loading} avatar>
                        <Meta
                        //   avatar={
                            // <Avatar
                            //   size={32}
                            //   style={{ backgroundColor: s.error ===null ?'#87d068':"red" }}
                            // //   src={s.logoUrl}
                            //   icon={<HomeOutlined />}
                            // //   style={ {opacity: "0.3" }}
                            // />
                        //   }
                          title={s.code}
                          description={
                            <>
                            <div style={{color:"black"}}>
                             {s.liveDayDetails && (<>
                               <p style={{width:"100%",display:"flex",justifyContent:"row",alignItems:"baseline"}}> <div className="live"></div> <span style={{marginLeft:"10px"}}> <Popover content={
                                <>
                                <p>🚀ID : {s.liveDayDetails.id}</p>
                                <p>🔗URL : {s.liveDayDetails.url}</p>
                                <p>📅Date : {new Date(s.liveDayDetails.date).toLocaleString()}</p>
                                </>
                               } title="Live class Details" trigger="click">
      <Button type="dashed"  size='small'><b>Live </b></Button>
    </Popover> </span> </p>
                             </>)}   
                             <p >Active : <b>Day {s.day}</b></p>
                             <p >Start Date : <b>{new Date(s.startDate).toLocaleString()}</b></p>
                             <p >Is Slot Active :  {s?.acceptingRegistration ?<Tag color={"green"}>Yes</Tag>:<Tag color={"red"}>No</Tag>}</p>
                             <p >Group Link  : 
          <a
                  style={{ fontWeight: "bold", marginLeft: "4px" }}
                  href={"https://bit.ly/" + s?.groupLink}
                  target="_blank"
                >
                  {s?.groupLink
                    ? "https://bit.ly/" + s?.groupLink
                    : "Not Available"}
                </a></p>
                {/* {s.error && ( <p style={{color:"red"}}><b><AlertOutlined style={{color:"red"}}/>{s.error}</b></p>)} */}
                {s.error && ( <p style={{color:"red"}}><b>{s.error.split("#").map((data)=>{
                  return (<>
                  <p><AlertOutlined style={{color:"red"}}/> {data}</p>
                  </>)
                })}</b></p>)}
                             </div>
                            </>
                          }
                        />
                      </Skeleton>
                    </Card>
                  </Col>
                )
              })}
        </Row>
        
        </>

    }

            
          </div>
   </>
  )
}

export default CurrentAnalytics